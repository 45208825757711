// extracted by mini-css-extract-plugin
export var container = "index-module--container--9f476";
export var heading = "index-module--heading--3c514";
export var moreTools = "index-module--moreTools--3d8e0";
export var moreToolsItem = "index-module--moreToolsItem--f871a";
export var moreToolsList = "index-module--moreToolsList--ee983";
export var moreToolsTitle = "index-module--moreToolsTitle--bf7b0";
export var resource = "index-module--resource--dd1df";
export var resourceBackground = "index-module--resourceBackground--86089";
export var resourceDescription = "index-module--resourceDescription--e24c9";
export var resourceHeading = "index-module--resourceHeading--098a8";
export var resources = "index-module--resources--367a9";
export var rotation = "index-module--rotation--1675e";